// import PlayedVideo from './components/PlayedVideo';
// import Homepage from './pages/Homepage';
// import LiveEvents from './pages/LiveEvents';
// import LiveTV from './pages/LiveTV';
// import Signin from './pages/Signin';
// import Signup from './pages/Signup';
// import Videos from './pages/Videos';
// import Profile from './pages/Profile';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import PaymentsMethod from './components/PaymentsMethod';
// import EditProfile from './components/EditProfile';
// import SideBar from './components/SideBar';
import LandingPage from './pages/LandingPage';
// import MoreTrending from './pages/moreTrending';
// import ForgotPassword from './pages/forgotpassword';
// import AuthService from "./services/auth.service";
// import PrivateRoute from "./PrivateRoute"
// import HelpPage from "./pages/Help"
// import VerifyEmail from "./pages/VerifyPassword"
// import ChangePassword from './pages/ChangePassword'
// import PrivacyPolicy from './pages/PrivacyPolicy'

import { useState, useEffect } from 'react';
import axios from 'axios';


function App() {
  const [data, setData] = useState(null);
  return (
    <div className="App">
      <Router>
          <Routes>
            <Route path="/" element={<div className=''> <LandingPage/> </div>} />
            {/* <Route path="/homepage" element={<div className='px-4'> <Homepage /> </div>} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/ForgotPassword" element={<ForgotPassword/>} />
            <Route path="/EditProfile" element={<EditProfile/>} />
            <Route path="/payments" element={<PaymentsMethod/>} />
            <Route path="/LiveEvents" element={<div className='px-4'> <LiveEvents /> </div>} />
            <Route path="/LiveTV" element={<div className='px-4'> <LiveTV /> </div>} />
            <Route path="/Videos" element={<div className='px-4'> <Videos /> </div>} />
            <Route path="/MoreTrending" element={<div className='px-4'> <MoreTrending /> </div>} />
            <Route path="/currentVideo" element={<div className='px-4'> <PlayedVideo /> </div>} />
            <Route path="/help" element={<div className=''> <HelpPage /> </div>} />
            <Route path="/verify-email/:id" element={<VerifyEmail />} />
            <Route path="/forgot-password/:id" element={<ChangePassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> */}
            
          </Routes>
      </Router>
    </div>
  );
}

export default App;


// Import your components here

// function App() {

//   const [data, setData] = useState(null);

//   return (
//     <div className="App">
//       <Router>
//         <DataContext.Provider value={{ data, setData }}>
//         <Routes>
//           <Route path="/" element={<div className=''> <LandingPage/> </div>} />
//           <Route path="/homepage" element={<div className='px-4'> <Homepage /> </div>} />
//           <Route path="/signin" element={<Signin />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/profile" element={<Profile />} />
//           <Route path="/ForgotPassword" element={<ForgotPassword/>} />
//           <Route path="/EditProfile" element={<EditProfile/>} />
//           <Route path="/payments" element={<PaymentsMethod/>} />
//           <Route path="/events" element={<div className='px-4'> <LiveEvents /> </div>} />
//           <Route path="/LiveTV" element={<div className='px-4'> <LiveTV /> </div>} />
//           <Route path="/Videos" element={<div className='px-4'> <Videos /> </div>} />
//           <Route path="/MoreTrending" element={<div className='px-4'> <MoreTrending /> </div>} />
//           <Route path="/currentVideo" element={<div className='px-4'> <PlayedVideo /> </div>} />
//           <Route path="/help" element={<div className=''> <HelpPage /> </div>} />
//         </Routes>
//         </DataContext.Provider>
//       </Router>
//     </div>
//   );
// }

// export default App;















// function App() {
//   return (
//     <>
//       {/* <Signin /> */}
//       {/* <Signup /> */}
//       {/* <div className='px-4'>
//         <Homepage />
//       </div> */}
//       {/* <div className='px-4'>
//         <LiveTV/>
//       </div> */}
//       {/* <div className='px-4'>
//       <LiveEvents/>
//       </div> */}
//       {/* <div className='px-4'>
//       <Videos/>
//       </div> */}
//       {/* <PlayedVideo/> */}
//       {/* <Profile/> */}
//     </>
//   );
// }
