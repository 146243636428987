import React from "react";
import { useNavigate, Link } from "react-router-dom";
import HeroImage from "../assets/images/HeroImage.png";
import laptop from "../assets/images/laptop 1.png";
import Applestore from "../assets/images/Applestore.png";
import Playstore from "../assets/images/Playstore.png";
import "@fontsource/urbanist";

const Landing = () => {
  return (
    <div>
      <div className="md:hidden block">
        <div className="flex flex-col justify-center items-center py-10 bg-HeroImage">
          <center>
            {" "}
            <p
              className="mb-8 text-white text-2xl"
              style={{ fontFamily: "Urbanist", fontWeight: "bold" }}
            >
              Stream <span className="text-red-600">LIVE</span> TVs, Events &
              Videos{" "}
            </p>
          </center>

          {/* <Link to="/signin">
            <a
              href=""
              class="py-2 px-3 bg-black hover:bg-white text-white hover:text-black rounded-full transition duration-300 border-white border-2"
            >
            Login
            </a>
          </Link> */}

          <div>
            <center>
              <div className="flex flex-col justify-center items-center py-2">
                <p className="mt-4 text-white text-sm ">
                  Also available for Android and iOS Devices
                </p>
                <div class="flex mt-3 items-center space-x-8">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kanemaonline.app&pli=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="h-8" src={Playstore} />
                  </a>
                    <a href="https://apps.apple.com/mw/app/kanema-online/id6449297998" target="_blank" rel="noopener noreferrer">
                    <img className="h-8" src={Applestore} />
                  </a>
                </div>
              </div>
            </center>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center py-5">
          <p className="text-white text-xl" style={{ fontWeight: "bold" }}>
            Entertainment Your Way
          </p>

          <center>
            <p className="mt-1 text-white text-sm ">
              {" "}
              Stream and watch on any network anywhere anytime
            </p>
          </center>

          <div class="md:flex items-center py-5">
            <img className="" src={laptop} />
          </div>

          <div className="flex flex-col justify-center items-center ">
            <p className="text-white text-xl" style={{ fontWeight: "bold" }}>
              {" "}
              MULTI-PLATFORM
            </p>

            <center>
              <p className="mt-2 text-white text-sm ">
                Watch on Computer, smartphone, Tablet, Smart TV and more
                unlimited devices
              </p>{" "}
            </center>

            <p className="mt-4 text-white text-lg"></p>
          </div>
        </div>

        {/* <div class="flex flex-col justify-center items-center">
                <p className='my-4 text-white text-xs'>© Static Computers Inc. All rights reserved.</p>
            </div> */}
        {/* 
                            <center><p className='mb-8 text-white text-2xl'>Stream and watch on any network anywhere anytime</p></center> */}
      </div>
      <div className="hidden md:block">
        <div class="grid grid-cols-2 gap-0">
          <div className="flex flex-col justify-center items-center">
            <p className="mb-8 text-white text-3xl">
              Stream <span className="text-red-600">LIVE</span> TVs, Events &
              Videos
            </p>

            {/* <Link to="/signin">
              <a
                href=""
                className="py-3 px-10 mb-8 bg-black hover:bg-white text-white hover:text-black rounded-full transition duration-300 border-white border-2"
                style={{ fontWeight: "bold", border: "1px solid white" }}
              >
                 Login
              </a>
            </Link> */}
            <p className="mt-8 text-white text-xl">
              Also available for Android and iOS Devices
            </p>
            <p>
              <div className="md:flex mt-6 items-center space-x-20">
                <a
                  href="https://play.google.com/store/apps/details?id=com.kanemaonline.app&pli=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="h-10" src={Playstore} />
                </a>
                <a href="https://apps.apple.com/mw/app/kanema-online/id6449297998" target="_blank" rel="noopener noreferrer">
                  <img className="h-10" src={Applestore} />
                </a>
              </div>
            </p>
          </div>

          <div className=" mt-10">
            <img className="" src={HeroImage} />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center py-8">
          <p className="text-white text-3xl  " style={{ fontWeight: "bold" }}>
            Entertainment Your Way
          </p>
          <p
            className="mt-2 text-white py-0 text-xl "
            style={{ marginBottom: "50px" }}
          >
            {" "}
            Stream and watch on any network anywhere anytime
          </p>

          <div class="md:flex items-center">
            <img src={laptop} />
          </div>

          <p
            className="text-white  py-2 text-3xl  "
            style={{ fontWeight: "bold" }}
          >
            MULTI-PLATFORM
          </p>
          <p className="mt-2 text-white py-0 text-xl ">
            Watch on Computer, Smartphone, Tablet, Smart TV and other unlimited
            devices
          </p>
          {/* <p className='mt-2 text-white text-xl py-10' style={{ fontWeight: "bold", marginBottom: "0px" }}></p>
                    <p className='mt-2 text-white' style={{ marginTop: "0px" }}></p> */}
        </div>
        {/* <div class="flex flex-col justify-center items-center">
                <p className='my-4 mt-16 text-white'>© Static Computers Inc. All rights reserved.</p>
            </div> */}
      </div>
    </div>
  );
};

export default Landing;
