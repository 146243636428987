import React from 'react'
import LandingNavBar from '../components/LandingNavBar'
import Landing from '../components/Landing'
import LandingPageFooter from '../components/LandingPagefooter'


const LandingPage = () => {
  return (
    <div className=' bg-zinc-900'>
        <LandingNavBar/>
        <Landing/>
        <LandingPageFooter />
    </div>
  )
}

export default LandingPage